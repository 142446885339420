import productQuery from "./graphql/product.graphql"
import productsQuery from "./graphql/products.graphql"

/**
 * Shopify Buy SDK supports products but doesn't include inventory. This client is for fetching
 * product inventory. All other product data should come from Sanity.
 */
export default class ProductClient {
  constructor(client) {
    this.client = client
  }

  /**
   * Fetches a product by ID. Consistent with the fetch method in the Buy SDK, this expects the ID
   * to already have been base64 encoded (`btoa`).
   * https://shopify.dev/docs/storefront-api/reference/common-objects/queryroot#products-2021-04
   */
  fetch(id) {
    return this.client.query({
      query: productQuery,
      variables: { id },
    })
  }

  fetchMultiple(ids) {
    return this.client.query({
      query: productsQuery,
      variables: { ids },
    })
  }
}
