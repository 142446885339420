import checkoutCustomerAssociateV2 from "./graphql/checkoutCustomerAssociateV2.graphql"
import checkoutCustomerDisassociateV2 from "./graphql/checkoutCustomerDisassociateV2.graphql"

/**
 * For most checkout related functionality we're using the Shopify Buy SDK. This client provides
 * functionality that is missing from the Buy SDK. This client should not attempt to return the
 * checkout objects. Instead, checkouts should be fetched using the Buy SDK to ensure that
 * checkout objects used throughout the app are consistent.
 */
export default class CheckoutClient {
  constructor(client) {
    this.client = client
  }

  /**
   * https://shopify.dev/docs/storefront-api/reference/checkouts/checkoutcustomerassociatev2
   */
  customerAssociateV2({ checkoutId, customerAccessToken }) {
    return this.client.mutate({
      mutation: checkoutCustomerAssociateV2,
      variables: { checkoutId, customerAccessToken },
    })
  }

  /**
   * https://shopify.dev/docs/storefront-api/reference/checkouts/checkoutcustomerdisassociatev2
   */
  customerDisassociateV2({ checkoutId }) {
    return this.client.mutate({
      mutation: checkoutCustomerDisassociateV2,
      variables: { checkoutId },
    })
  }
}
