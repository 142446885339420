import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import CheckoutClient from "./CheckoutClient"
import CustomerClient from "./CustomerClient"
import ProductClient from "./ProductClient"

/**
 * Shopify Buy SDK has support for checkout, collection, product, and shop queries. This client
 * adds support for additional APIs.
 */
export default class ShopifyClient {
  constructor({ domain, storefrontAccessToken }) {
    const httpLink = createHttpLink({
      uri: `https://${domain}/api/2021-04/graphql.json`,
    })

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
      }
    })

    this.client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    })

    this.checkout = new CheckoutClient(this.client)
    this.customer = new CustomerClient(this.client)
    this.product = new ProductClient(this.client)
  }
}
