import customerAccessTokenCreate from "./graphql/customerAccessTokenCreate.graphql"
import customerAccessTokenDelete from "./graphql/customerAccessTokenDelete.graphql"
import customerActivateByUrl from "./graphql/customerActivateByUrl.graphql"
import customerCreate from "./graphql/customerCreate.graphql"
import customerNodeQuery from "./graphql/customerNodeQuery.graphql"
import customerRecover from "./graphql/customerRecover.graphql"
import customerResetByUrl from "./graphql/customerResetByUrl.graphql"

export default class CustomerClient {
  constructor(client) {
    this.client = client
  }

  /**
   * https://shopify.dev/docs/storefront-api/reference/customers/customeraccesstokencreate
   */
  accessTokenCreate({ email, password }) {
    return this.client.mutate({
      mutation: customerAccessTokenCreate,
      variables: { input: { email, password } },
    })
  }

  /**
   * https://shopify.dev/docs/storefront-api/reference/customers/customeraccesstokendelete
   */
  accessTokenDelete(customerAccessToken) {
    return this.client.mutate({
      mutation: customerAccessTokenDelete,
      variables: { customerAccessToken },
    })
  }

  /**
   * https://shopify.dev/api/storefront/reference/customers/customeractivatebyurl
   */
  activateByUrl({ activationUrl, password }) {
    return this.client.mutate({
      mutation: customerActivateByUrl,
      variables: { activationUrl, password },
    })
  }

  /**
   * https://shopify.dev/api/storefront/reference/customers/customercreate
   */
  create(input) {
    return this.client.mutate({
      mutation: customerCreate,
      variables: { input },
    })
  }

  /**
   * https://shopify.dev/docs/storefront-api/reference/common-objects/queryroot#customer-2021-04
   */
  fetch(customerAccessToken) {
    return this.client.query({
      query: customerNodeQuery,
      variables: { customerAccessToken },
    })
  }

  /**
   * https://shopify.dev/docs/storefront-api/reference/customers/customerrecover
   */
  recover(email) {
    return this.client.mutate({
      mutation: customerRecover,
      variables: { email },
    })
  }

  /**
   * https://shopify.dev/docs/storefront-api/reference/customers/customerresetbyurl
   */
  resetByUrl({ password, resetUrl }) {
    return this.client.mutate({
      mutation: customerResetByUrl,
      variables: { password, resetUrl },
    })
  }
}
